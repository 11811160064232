<template>
    <div class="wandou">
        <div class="container"></div>
        <div class="search-content">
            <span class="search-title">组织架构：</span>
            <a-tree-select style="width: 200px;margin-right:10px" :maxTagCount="1" :treeData="treeDataSchool" treeNodeFilterProp="title" :value="schoolVal" @change="onChangeSchool" treeCheckable :showCheckedStrategy="SHOW_PARENT" placeholder="请选择中心"/>
            <span class="search-title">日期范围：</span>
            <a-range-picker style="width: 250px;margin-right:10px" v-model="selectDate" @change="_onChange"/>
            <a-button style="margin-right:10px" @click="_reset()">重置</a-button>

            <a-button style="margin-right:10px" type="primary" @click="_search">提交</a-button>
        </div>

        <div class="table-list">
            <div v-if="dataCount" style="display: flex;justify-content: center;border-right: 1px solid #e8e8e8; border-left: 1px solid #e8e8e8; border-top: 1px solid #e8e8e8;padding: 20px;">
                <div style="padding: 20px;" v-for="item in dataCount">
                    <a-statistic :title="item.Title" :value="item.value" >
                    </a-statistic>
                </div>
            </div>
            <a-table :columns="columns" :data-source="infoData" :pagination="false" @change="handleTableChange">
                    <span slot="tags" slot-scope="tags ,record, index">
                        <a-tag v-for="tag in tags" :key="tag" :color="'blue'"> {{ tag }} </a-tag>
                        <a-icon type="edit" @click="editTags(record.Label,record.StudentId)" />
                        <a-modal  title="选择标签" :visible="TagsVisible" @ok="submitTags(SelectedTags)" @cancel="cancelTags" :mask="false" :centered="true" :destroyOnClose="true">
                            <a-select mode="tags" style="width: 250px;margin-right:10px" :maxTagCount="2" placeholder="标签" v-model="SelectedTags">
                                <a-select-option v-for="item in tagSelector" :value="item"  >
                                    {{ item }}
                                </a-select-option>
                            </a-select>
                        </a-modal>
                    </span>

                <div slot="remark" slot-scope="text, record, index">
                    <p>{{ record.Remark }}</p>
                    <a-icon type="edit" @click="editRemark(record.Remark,record.StudentId)" />
                    <a-modal title="填写备注" :visible="RemarkVisible" @ok="submitRemark(RemarkContent)" @cancel="cancelRemark" :mask="false" :centered="true" :destroyOnClose="true">
                        <a-textarea placeholder="请填写备注" v-model="RemarkContent" >{{record.Remark}}</a-textarea>
                    </a-modal>
                </div>
            </a-table>
            <a-pagination show-size-changer v-model="currPage" v-if="DataCount > 10" style="margin-top: 18px;text-align: right;" @change="_changePage" :total="DataCount"  show-less-items :page-size-options="pageSizeOptions" show-quick-jumper @showSizeChange="onShowSizeChange" />
        </div>
    </div>

</template>

<script>
    import person from "../../components/SelectPersonnel/SelectPersonnel"; //选人
    import { TreeSelect } from 'ant-design-vue';

    export default {
        name: "wandou",
        components: {
            person
        },
        data(){
            return{
                dataCount:[
                    {Title: "兑换课次", FieldName: "LessonNum", CountType: "plus", value: 2200},
                    {Title: "兑换订单数", FieldName: "Id", CountType: "count", value: 100},
                    {Title: "兑换人数", FieldName: "StudentId", CountType: "unique", value: 6000}
                ],
                userInfo: JSON.parse(window.sessionStorage.getItem('userInfo')),
                personObj: {
                    rank: 0, //职级 0为不选，1为选
                    nums: 10000, //选的数量
                    department: 2, //0不选部门，1为都选（人 部门都选），2为只选部门，选人
                    contacts: "", //常用联系人 不要常用联系人传空''
                    selectArr: [], //要传入的数据
                },
                currPage:1,
                Limit:10,
                DataCount:0,
                selectDate: [],
                treeDataSchool:[],
                schoolVal: ['4ae815d133588c71013358ad96450007'],
                SHOW_PARENT: TreeSelect.SHOW_PARENT,
                pageSizeOptions:['10', '20', '40', '50','100'],
                tagSelector: [],
                infoData: '',
                Order: '',
                OrderField:'',
                TimeType:'',
                StudentStatus:'',
                StudentTags: undefined,
                SelectedTags: undefined,
                statusList: [],
                RemarkVisible: false,
                TagsVisible: false,
                RemarkContent: '',
                RemarkStudentId: '',
                columns:[
                    {
                        dataIndex: 'Num',
                        key: 'Num',
                        title: '序号'
                    },
                    {
                        title: '中心',
                        dataIndex: 'StudentName',
                        key: 'StudentName',
                    },
                    {
                        title: '已兑换课次',
                        dataIndex: 'UserName',
                        key: 'UserName',
                    },
                    {
                        title: '兑换人次',
                        dataIndex: 'StatusName',
                        key: 'StatusName',
                    },
                    {
                        title: '剩余正式课次',
                        dataIndex: 'Birthday',
                        key: 'Birthday',
                        sorter:true,
                    }
                ],
            }

        },

        created(){
            let that = this;
            this.$axios.get(8008, {}, res => {
                if (res.data.code == 1) {
                    this.treeDataSchool = res.data.data;
                } else {
                    this.$message.errpr(res.data.msg)
                }
            });
        },

        mounted(){
            this._search();
        },

        methods: {
            onChangeSchool (value) {
                this.schoolVal = value;
            },
            _onChange(date){
                this.selectDate = [this.__moment__(date[0]).format('YYYY-MM-DD'),this.__moment__(date[1]).format('YYYY-MM-DD')];
            },
            _reset(){
                this.schoolVal = [];
                this.selectDate = [];
                this.currPage = 1;
                this.Limit = 10;
                this.OrderField = '';
                this.Order = '';
                this.TimeType = '';
                this.StudentStatus = '';
                this.StudentTags = undefined;
            },
            _search(){
                this.$message.loading('搜索中...',1);
                this.$axios.post(80001121, {
                    SchoolId: this.schoolVal.length == 0 ? '' : this.schoolVal.join(','),
                    StartDate: this.selectDate.length == 0 ? '' : this.selectDate[0],
                    EndDate: this.selectDate.length == 0 ? '' : this.selectDate[1],

                }, res => {
                    this.$message.destroy();
                    if(res.data.code==1){
                        this.infoData = res.data.data;
                        this.dataCount = res.data.dataCount;
                    } else {
                        this.infoData = [];
                        this.$message.warning(res.data.msg,1);
                    }
                });
            },
            _changePage(p){
                this.currPage = p;
                this. _search();
            },
            handleTableChange(pagination, filters, sorter){
                this.OrderField = sorter.field;
                switch (sorter.order) {
                    case 'ascend':
                        this.Order = 'asc';
                        break;
                    case 'descend':
                        this.Order = 'desc';
                        break;
                }

                this._search();
            },
            onShowSizeChange(current, pageSize){

                this.currPage = current;
                this.Limit = pageSize;

                this. _getData();
            },
            editRemark(remark,studentid){
                this.RemarkVisible = true;
                this.RemarkContent = remark ? remark : '';
                this.RemarkStudentId = studentid;
            },
            submitRemark(content){
                this.RemarkVisible = false;
                this.RemarkContent = content;
                console.log( content + ' submitted! studentid:' + this.RemarkStudentId );

                this.$axios.post(8000113, {
                    StudentId: this.RemarkStudentId,
                    Content: content,
                }, res => {
                    this.$message.destroy();
                    if(res.data.code==1){
                        this.$message.success(res.data.msg);
                        this._search();
                    } else {
                        this.$message.error(res.data.msg);
                    }
                });
                //清空remark内容
                this.RemarkContent = '';
            },
            cancelRemark(){
                this.RemarkVisible = false;
            },
            editTags(label,studentid){
                this.TagsVisible = true;
                this.SelectedTags = label ? label : '';
                this.TagsStudentId = studentid;
                console.log('database.label:'+label);
                console.log(studentid);
            },
            submitTags(content){
                this.TagsVisible = false;
                this.SelectedTags = content.join(',');

                this.$axios.post(8000113, {
                    StudentId: this.TagsStudentId,
                    Content: content.join(','),
                    Type: 'Label',
                }, res => {
                    this.$message.destroy();
                    if(res.data.code==1){
                        this.$message.success(res.data.msg);
                        this._search();
                    } else {
                        this.$message.error(res.data.msg);
                    }
                });
                //清空remark内容
                this.SelectedTags = '';
            },
            cancelTags(){
                this.TagsVisible = false;
            },

        }

    }


</script>

<style lang="less" scoped>
    .wandou {
        width: 100%;
        min-height: 100%;
        background-size: 100%;
        position: relative;
        border-radius: 5px;
        background-color: #FFF;
        padding: 15px;
    }

    .table-list{
        margin-top: 30px;
        margin-left: 20px;
    }

    .search-item{
        margin-left: 19px;
        margin-top: 33px;
        display: inline-flex;
    }

    .shade{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #000;
        opacity: .5;
        z-index: 99999;
    }
    .seevideo{
        position: absolute;
        top: 1%;
        left: 49%;
        transform: translate(-50%, 50%);
        z-index: 999999;
    }

    .stat-data{
        width: 80%;
        background-color: #eff6ff;
        margin-left: 20px;
        height:150px;
        padding:30px;
        margin-top: 20px;
    }

    .stat-item{
        width: 22%;
        display: -ms-flexbox;
        height: 90px;
        background-color: #ffffff;
        margin-right: 20px;
        display: inline-block;
    }
    .stat-text{
        margin: 25px;
    }
    .stat-num{
        font-weight: 600;
        font-size:18px;
    }
    .stat-text-title{
        font-weight: 300;
        font-size: 12px;
    }
</style>